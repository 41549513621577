/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

    export function htmlDecode(profile, key = 'fancy_title')
      {
        var el = document.createElement("div");
        el.innerHTML = profile[key];
        profile[key] = el.innerText || el.textContent;
        return profile[key];
    }

    export function convertDate(profile, key = "createDate") {
        if (!profile[key]) {
          return null;
        }
        let testUnformatted = profile[key];
        let date = new Date(testUnformatted);
        let options = {timeStyle: 'short', dateStyle: 'long'};
        let formattedDate = date.toLocaleString(date, options);
        return formattedDate;
     }


export function highlightField(fieldValue, matchedSubstrings) {
  let highlightedString = fieldValue;

  // We must first sort the matchedSubstrings by decreasing offset. 
  const sortedMatches = matchedSubstrings.slice()
    .sort((match1, match2) => match2.offset - match1.offset);

  sortedMatches.forEach(match => {
    const { offset, length } = match;
    highlightedString = 
      highlightedString.substr(0, offset) +
      `<mark>${fieldValue.substr(offset, length)}</mark>`+
      highlightedString.substr(offset + length);
  });

  return highlightedString;
}